import "./App.css";
import "./assets/css/innerlayout.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AuthLayout from "./layouts/authLayout";
import InnerLayout from "./layouts/innerLayout";
// import Step1 from "./pages/step1";
import Step2 from "./pages/step2";
// import Step3 from "./pages/step3";
import Step4 from "./pages/step4";
import TestComplete from "./pages/testcomplete";
import Home from "./pages/home";
import Questions from "./pages/questions";
import Review from "./pages/review";

function App() {
  return (
    <Router>
      {/* Front */}
      <Routes>
        <Route path="/" element={<AuthLayout />}>
          <Route path="/" element={<Step2 />} />
          {/* <Route path="register" element={<Step1 />} /> */}
          <Route path="login" element={<Step2 />} />
          {/* <Route path="verification" element={<Step3 />} /> */}
          <Route path="downloadquestionpaper" element={<Step4 />} />
          <Route path="testcompleted" element={<TestComplete />} />
        </Route>
        <Route path="/" element={<InnerLayout />}>
          <Route path="starttest" element={<Home />} />
          <Route path="questions" element={<Questions />} />
          <Route path="review" element={<Review />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
