import React from "react";
import Countdown from "../components/countdown";

import { useState, useEffect } from "react";
import { common } from "../helpers/common";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

function Home() {

  const [countdown, setCountdown] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [testDuration, setTestDuration] = useState(false);
  const [testDurationOver, setTestDurationOver] = useState(false);
  const [countDownTime, setCountDownTime] = useState(new Date());
  const rightArrow = require("../assets/imgs/right-arrow.svg").default;
  const navigate = useNavigate();

  // effect
  useEffect(() => {
    let token = common.localGet("token") || null;
    if(!token) {
      navigate('/register');
      return
    }
    getStartTime();
    const timer = setInterval(() => {
      let timeLeft = getTimeLeft()
      if (
        timeLeft.Days <= 0 
        && timeLeft.Hrs <= 0
        && timeLeft.Min <= 0
        && timeLeft.Sec <= 0
      ) {
        setTimeLeft(timeLeft);
        clearInterval(timer);
      } else {
        setTimeLeft(timeLeft);
      }
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const getStartTime = _ => {
    let res = common.localGet('setting', true);
    if(res && res !== '{}') {
      if (res && res.startDate && res.testDuration) {
        const {startDate, testDuration} = res;
        const countdown = common.remainingSecondsfromDate(
          common.formatDate(startDate, 'YYYY-MM-DDTHH:mm:ssZ'),
        );
        let endDate = common.formatDate(startDate, 'YYYY-MM-DDTHH:mm:ssZ');
        endDate = moment(endDate, 'YYYY-MM-DDTHH:mm:ssZ').add(
          testDuration,
          'minutes',
        );
        const duration = common.remainingSecondsfromDate(endDate);

        setCountdown(countdown ? parseInt(countdown) : null);
        setStartDate(startDate);
        setTestDuration(testDuration);
        setTestDurationOver(duration > 0 ? false : true);
      } else {
        // this.props.navigation.replace("")
      }
    }
  };

  const getTimeLeft = () => {
    let res = common.localGet('setting', true);
    if (res && res.startDate) {
      const totalTimeLeft = new Date(res.startDate) - new Date();
      const Days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
      const Hrs = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
      const Min = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
      const Sec = Math.floor((totalTimeLeft / 1000) % 60);
      return { Days, Hrs, Min, Sec };
    }
    return { Days: 0, Hrs: 0, Min: 0, Sec: 0 };
  };
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());

  const isDurationOver = () => {
    if (
      timeLeft.Days <= 0 
      && timeLeft.Hrs <= 0
      && timeLeft.Min <= 0
      && timeLeft.Sec <= 0
    ) {
      return true
    }
    return false
  }

  const startTest = _ => {
    let endDate = common.formatDate(startDate, 'YYYY-MM-DDTHH:mm:ssZ');
    endDate = moment(endDate, 'YYYY-MM-DDTHH:mm:ssZ').add(
      testDuration,
      'minutes',
    );
    const duration = common.remainingSecondsfromDate(endDate);
    if (duration > 0) {
      navigate("/questions", { state: {startDate, testDuration} });
    } else {
      common.notify('E', 'No Tests are available');
      setTestDurationOver(true);
    }
  };
  
  const logout = _ => {
    common.localRemove('token');
    common.localRemove('userPhone');
    common.localRemove('question_paper');
    common.localRemove('setting');
    common.localRemove('userDetails');
    navigate("/");
  };

  return (
    <div className="content-wrap">
      <div className="container">
        <div className="col-md-10 mx-auto inner-wrap text-center">
          <p className="card-title">4th Chapter Convention on Quality Concepts 3CQC – 2023</p>
          <p className="countdown-txt">
            Theme: “Cultural change through quality concepts”
          </p>
          <p className="countdown-txt">{common.formatDate(startDate, 'DD MMMM YYYY | hh:mm a')}{' '}</p>
          {!isDurationOver() && (
            <label className="steps-label">Starts In</label>
          )}
           {!isDurationOver() && (
            <div className="countdown-wrap pb-4">
              {Object.entries(timeLeft).map((el) => {
                const label = el[0];
                const value = el[1];
                return (
                  <div className="box" key={label}>
                    <div className="value">
                      <span>{value}</span>
                    </div>
                    <span className="label">{label}</span>
                  </div>
                );
              })}
              {/* <Countdown /> */}
            </div>
          )}
          {isDurationOver() && testDurationOver === false && (
          <div className=" get-started mt-5">
              <button className="btn btn-primary save-btn" onClick={startTest}>
                  Start Test!
              </button>
              <img
                src={rightArrow}
                alt="doc"
                width={11}
                height={19}
                className="arrow-right"
              />
          </div>
          )}
          {isDurationOver() && testDurationOver === true && (
            <div className=" get-started mt-3">
                <button className="btn btn-secondary save-btn" onClick={logout}>
                  Logout
                </button>
                <img
                  src={rightArrow}
                  alt="doc"
                  width={11}
                  height={19}
                  className="arrow-right"
                />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
