import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { common } from "../helpers/common";
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';
import Countdown from 'react-countdown';
import moment from "moment";
import { api } from "../helpers/api";
const answerLegends = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
const Questions = () => {
  const location = useLocation();
  const slickRef = useRef();
  const questionCounts = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 14,
    slidesToScroll: 4,
    initialSlide: 0,
    arrow: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 12,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [questions, setQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [duration, setDuration] = useState(0);
  const [setting, setSetting] = useState();
  const [answered, setAnswered] = useState({
    selectedAnswers: [],
    totScore: 0,
    wrong: 0,
    correct: 0,
  });
  const navigate = useNavigate();

  const goToNextQuestion = _ => {
    if (currentIndex !== questions.length - 1) {
      // handleGoToSlide(currentIndex + 1);
      setCurrentIndex(prev => prev + 1);
    }
  }

  const goToPrevQuestion = _ => {
    if (currentIndex !== 0) {
      // handleGoToSlide(currentIndex - 1);
      setCurrentIndex(prev => prev - 1);
    }
  }

  const handleQuestionClick = (i) => {
    setCurrentIndex(i);
    // handleGoToSlide(i);
  }

  const updateAnswer = (key, selectedAnswer, item) => {
    answered.selectedAnswers[currentIndex] = {
      id: item._id,
      correct:
        item.answers.content[key] && item.answers.content[key].correct,
      selectedAnswer,
      key,
      answer: item.answer,
    };
    setAnswered((prevState) => ({
      ...prevState,
      selectedAnswers: answered.selectedAnswers,
    }));
    common.localSet("answered", JSON.stringify(answered));
  }

  useEffect(() => {
    const quesList = JSON.parse(common.localGet("question_paper"));
    if (quesList && quesList.length > 0) {
      quesList.forEach((q, i) => {
        q = common.formatText(q);
        quesList[i] = q;
      })
    } else {
      navigate("/register");
    }
    setQuestions(quesList);

    let newSetting = common.localGet('setting', true);
    setSetting(newSetting);

    if(location.state && location.state.selIndex >= 0) {
      setCurrentIndex(location.state.selIndex);
    }
    let getSelectedAnswers = common.localGet("answered", true);
    if(getSelectedAnswers) {
      setAnswered((prevState) => ({
        ...prevState,
        selectedAnswers: getSelectedAnswers.selectedAnswers,
      }));
    }
  }, []);

  useEffect(() => {
    if(currentIndex > 0) {
      handleGoToSlide(currentIndex);
    }
  }, [currentIndex])

  const getSelectedBlock = (key) => {
    return answered.selectedAnswers[currentIndex] && answered.selectedAnswers[currentIndex].key == key;
  }

  const isAnswered = (i) => {
    return answered.selectedAnswers[i] ? true : false;
  }

  const goToReview = () => {
    common.localSet("answered", JSON.stringify(answered));
    navigate("/review", { state: { isSubmitted: false } });
  }

  const timeRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if(!common.localGet("questionsAutoSubmit")) {
        common.localSet("questionsAutoSubmit", true);
        onSubmit();
      }
    }
    return (
      <p>
        <span>{hours}</span>
        <span>:</span>
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </p>
    );
  };

  const handleGoToSlide = (slideIndex) => {
    if (slickRef.current) {
      slickRef.current.slickGoTo(slideIndex, true);
    }
  }

  const onSubmit = () => {
    try {
      let answeredLocal = common.localGet("answered", true);
      answeredLocal.totScore = 0;
      answeredLocal.correct = 0;
      answeredLocal.wrong = 0;
      answeredLocal.selectedAnswers.map(i => {
        if (i && i.answer && i.answer[i.key] && i.answer[i.key].value) {
          answeredLocal.totScore = answeredLocal.totScore + 1;
          answeredLocal.correct = answeredLocal.correct + 1;
        } else {
          answeredLocal.wrong = answeredLocal.wrong + 1;
        }
      });
      let data = {
        url: "post_answers",
        auth: "token",
        method: "POST",
        body: { ...answeredLocal }
      };
      api.call(data, (response) => {
        if (response.data.http_code !== 200) {
          common.notify("E", response.data.message);
        } else {
          logout();
          common.notify("E", "Test has been submitted successfully");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  const logout = _ => {
    common.localRemove('token');
    common.localRemove('userPhone');
    common.localRemove('question_paper');
    common.localRemove('setting');
    common.localRemove('answered');
    common.localRemove('userDetails');
    common.localRemove('questionsAutoSubmit');
    navigate("/testcompleted");
  };

  const getEndDate = () => {
    if(setting && setting.startDate) {
      const startDate = new Date(setting.startDate);
      const testDurationInSeconds = Number(setting.testDuration);
      const endDate = new Date(startDate.getTime() + testDurationInSeconds * 60 * 1000);
      return endDate;
    }
    return null
  }

  const getCountDown = () => {
    if(setting && setting.startDate && getEndDate()) {

      // const startDate = new Date(setting.startDate);
      // const testDurationInSeconds = Number(setting.testDuration);
      // const endDate = new Date(startDate.getTime() + testDurationInSeconds * 60 * 1000);

      return (
        <div>
          <Countdown date={getEndDate()} renderer={timeRenderer} />
        </div>
      )
    }
    return null
  }

  return (
    <div className="page-wrap">
      <div className="container-fluid questions-header-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto question-header">
              <p className="qheader-txt1">
              4th Chapter Convention on Quality Concepts 3CQC – 2023
              </p>
              <p className="qheader-txt2">
                Theme: “Cultural change through quality concepts”
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid questions-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto question-count-wrap">
              <Slider {...questionCounts} ref={slickRef}>
                {questions?.map((q, i) => (
                  <div 
                  className={`question-num ${ isAnswered(i) ? 'answered' : ''}`} 
                  // className="question-num answered" 
                  onClick={() => handleQuestionClick(i)}
                  key={i}
                  >
                    <span>{i + 1}</span>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="col-md-10 mx-auto question-list">
              <div>
                <p>
                  <span>Q.{currentIndex + 1}</span>
                  <span dangerouslySetInnerHTML={{ __html: questions[currentIndex]?.content }}></span>
                </p>
              </div>
              <div>
                <div className="timer-wrap">
                  { getCountDown() }
                  {/* {
                    setting && setting.startDate && (
                      <div>
                        <Countdown date={new Date(new Date(setting.startDate) + (setting.testDuration * 1000))} renderer={timeRenderer} />
                      </div>
                    )
                  } */}
                  {/* <p>
                    <span>02</span>
                    <span>:</span>
                    <span>59</span>
                    <span>:</span>
                    <span>55</span>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container answer-wrap">
        <div className="row">
          <div className="col-md-10 mx-auto">
            {questions[currentIndex]?.answers?.content && 
            Object.keys(questions[currentIndex].answers.content)?.map((key, i) => {
              const item = questions[currentIndex].answers.content[key].content;
              return (
                <div className="options-wrap" key={key + i} onClick={() => updateAnswer(key, questions[currentIndex].answers.content[key], questions[currentIndex])}>
                  <input
                    type="radio"
                    name={"q1-options" + key + i}
                    id={"q1-options" + key + i}
                    className="option-input"
                    checked={getSelectedBlock(key)}
                  />
                  <label htmlFor="q1-option1" className="options w-100">
                    <div className="option-inner">
                      <span className="option-letter">{answerLegends[i]}.</span>
                      {Object.keys(item)?.map((o, idx) => (
                        <span key={idx} dangerouslySetInnerHTML={{ __html: item[o].content }}>
                        </span>
                      ))}
                    </div>
                  </label>
                </div>)
            })}
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 mx-auto answer-foot">
            <button onClick={goToPrevQuestion} className={`btn btn-answer ${currentIndex === 0 ? "disabled" : ""}`}>Previous</button>
            <span>{currentIndex + 1}/{questions.length}</span>
            <button onClick={goToNextQuestion} className={`btn btn-answer  ${currentIndex === questions.length - 1 ? "d-none" : ""}`}>Next</button>
            <button onClick={goToReview} className={`btn btn-answer  ${currentIndex === questions.length - 1 ? "" : "d-none"}`}>Review</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
