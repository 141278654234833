import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useEffect } from "react";

import { common } from "../helpers/common";

function InnerLayout(props) {  
  return (
    <>
      <Navbar expand="lg" className="">
        <Container fluid>
          <Navbar.Brand>
            <img src={common.loadImg("logo.svg")} alt="logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          {/* <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavDropdown
                align="end"
                title={
                  <img
                    src={common.loadImg("profile-icn.svg")}
                    alt="logo"
                    className="profile-icn"
                  />
                }
                id=""
              >
                <NavDropdown.Item href="#">Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse> */}
        </Container>
      </Navbar>
      <div className="main-wrap">
        <Outlet {...props} />
      </div>
    </>
  );
}

export default InnerLayout;
