import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useNavigate } from "react-router-dom";
import { api } from "../helpers/api";
import { common } from "../helpers/common";

function Step4() {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [userDetail, setUserDetail] = useState("");
  const [questionPaper, setQuestionPaper] = useState([]);
  const imageDoc = require("../assets/imgs/document-icn.svg").default;
  const cloudImg = require("../assets/imgs/cloud.svg").default;
  const refresh = require("../assets/imgs/refresh-icn.svg").default;
  const tick = require("../assets/imgs/tick.svg").default;
  const rightArrow = require("../assets/imgs/right-arrow.svg").default;
  const navigate = useNavigate();

  // effect
  useEffect(() => {
    let getUserDetail = common.localGet("userDetails", true) || null;
    if(getUserDetail) {
      setUserDetail(getUserDetail);
      partialProgressBar();
    } else {
      navigate('/register');
    }
  }, []);

  useEffect(() => {
    if (userDetail !== "") {
      downloadQuestion();
      getStartTime();
    }
  }, [userDetail]);

  const downloadQuestion = () => {
    try {
      let data = {
        url: "question",
        query: "/" + userDetail.topic._id + '/topic',
        method: "GET"
      };
      setError("process");
      api.call(data, (response) => {
        if (response.data.http_code !== 200) {
          common.notify("E", 'Failed to download question');
          setError("error");
        } else {
          progressBar(100);
          common.localSet(
            'question_paper',
            JSON.stringify(response.data.data),
          );
        }
      }); 
    } catch (error) {
      console.log(error);
      setError("error");
    }
  }

  const getStartTime = () => {
    try {
      let data = {
        url: "get_start_time",
        method: "GET"
      };
      api.call(data, (response) => {
        if (response.data.http_code !== 200) {
          common.notify("E", 'Failed to get start time');
        } else {
          common.localSet(
            'setting',
            JSON.stringify({
              startDate: response.data.data.startTime,
              testDuration: response.data.data.testDuration,
            }),
          );
        }
      }); 
    } catch (error) {
      console.log(error);
    }
  }

  const partialProgressBar = () => {
    let progress = 0;
    const progressInterval = setInterval(() => {
      progress += 1;
      if (progress > 10) {
        progress = 10;
        clearInterval(progressInterval);
      }
      setPercentage(progress);
    }, 5);

    return () => {
      clearInterval(progressInterval);
    };
  }

  const progressBar = () => {
    let progress = 10;
    const progressInterval = setInterval(() => {
      progress += 1;
      if (progress > 100) {
        progress = 100;
        clearInterval(progressInterval);
        setError("success");
      }
      setPercentage(progress);
    }, 100);

    return () => {
      setError("success");
      clearInterval(progressInterval);
    };
  }

  const getStartedNow = _ => {
    navigate("/starttest");
  };

  return (
    <div className="row vertical-align bg ">
      <div className="col-md-4">
        <div className="auth-wrap">
          <div className="inner-wrap">
            <div className="step">
              <span className="step-content">STEP 4</span>
            </div>


            {error == null || error === 'process' ? (
              <div>
                <div className="progress-wrap">
                  <img src={imageDoc} alt="doc" width={74} height={86} />
                  <h1 className="auth-title mt-3">Question Paper { error }</h1>
                  <h2 className="auth-title">Downloading...</h2>
                </div>
                <div
                  className="progressbar"
                  style={{ textAlign: "center", fontSize: "30px" }}
                >
                  {" "}
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      pathColor: `#47269B`,
                      textColor: "#47269B",
                      trailColor: "#D3C7F4",
                      backgroundColor: "#47269B",
                    })}
                  />
                </div>
              </div>
            ) : error === 'error' ? (
              <div>
                <div className="progress-wrap">
                  <img src={imageDoc} alt="doc" width={74} height={86} />
                  <h1 className="auth-title mt-3">Question Paper</h1>
                  <h2 className="auth-title">Download Error</h2>
                </div>
                <div className="img-error-wrap mt-5">
                  <img src={cloudImg} alt="doc" width={131} height={117} />
                </div>
                <div className="try-wrap ">
                  <img
                    src={refresh}
                    alt="doc"
                    width={22}
                    height={26}
                    className="me-3"
                  />
                  <p className="try-again">Try Again</p>
                </div>
              </div>
            ) : (
              <div>
                <div className="progress-wrap">
                  <img src={imageDoc} alt="doc" width={74} height={86} />
                  <h1 className="auth-title mt-3">Question Paper</h1>
                  <h2 className="auth-title">Download.</h2>
                </div>
                <div className="img-error-wrap mt-5">
                  <img src={tick} alt="doc" width={125} height={125} />
                </div>
                <div className=" get-started mt-5">
                  <button className="btn btn-primary save-btn" onClick={getStartedNow}>
                    Get Started Now!
                  </button>
                  <img
                    src={rightArrow}
                    alt="doc"
                    width={11}
                    height={19}
                    className="arrow-right"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <div className="auth-wrap mt-5">
          <div className="inner-wrap">
            <div className="step">
              <span className="step-content">STEP 4</span>
            </div>
            <div className="progress-wrap">
              <img src={imageDoc} alt="doc" width={74} height={86} />
              <h1 className="auth-title mt-3">Question Paper</h1>
              <h2 className="auth-title">Download Error</h2>
            </div>
            <div className="img-error-wrap mt-5">
              <img src={cloudImg} alt="doc" width={131} height={117} />
            </div>
            <div className="try-wrap ">
              <img
                src={refresh}
                alt="doc"
                width={22}
                height={26}
                className="me-3"
              />
              <p className="try-again">Try Again</p>
            </div>
          </div>
        </div> */}

        {/* <div className="auth-wrap mt-5">
          <div className="inner-wrap">
            <div className="step">
              <span className="step-content">STEP 4</span>
            </div>
            <div className="progress-wrap">
              <img src={imageDoc} alt="doc" width={74} height={86} />
              <h1 className="auth-title mt-3">Question Paper</h1>
              <h2 className="auth-title">Download.</h2>
            </div>
            <div className="img-error-wrap mt-5">
              <img src={tick} alt="doc" width={125} height={125} />
            </div>
            <div className=" get-started mt-5">
              <button className="btn btn-primary save-btn">
                Get Started Now!
              </button>
              <img
                src={rightArrow}
                alt="doc"
                width={11}
                height={19}
                className="arrow-right"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Step4;
