import { common } from "./common";
import { config } from "./config";

// const
const base_url = config.api.url + ":3000/api/v1/web";

// services
const services = {
  // auth
  auth_login: base_url + "/user",
  auth_login_new: base_url + "/login-new",
  auth_otp_verify: base_url + "/otp/verify",
  auth_otp_generate: base_url + "/otp/generate",
  auth_password_reset: base_url + "/password/reset",
  profile_password_reset: base_url + "/profile/password/reset",
  company: base_url + "/company",
  team: base_url + "/team",
  topic: base_url + "/topic",
  user_otp: base_url + '/user/otp',
  user_login: base_url + '/login',
  question: base_url + '/question',
  get_start_time: base_url + '/setting',
  post_answers: base_url + '/user/answers'
};

// api call
export const api = {
  async call(data = {}, response, final) {
    getData(data);
    // server side rendering
    if (data.ssr) {
      let myPromise = new Promise(function (resolve, reject) {
        fetch(data.url, getOptions(data))
          .then(async (response) => {
            return await response.text().then((data) => ({
              status: response.status,
              data: data,
            }));
          })
          .then((result) => {
            if (validateData(result)) {
              try {
                result.data = JSON.parse(result.data);
              } catch (e) {
                result.data = {
                  error: e.toString(),
                };
              }
              notify(data, result);
              resolve(result);
            }
          })
          .catch((err) => {
            let result = {
              status: 404,
              data: {
                error: err.toString(),
              },
            };
            notify(data, result);
            resolve(result);
          });
      });
      return myPromise;
    } else {
      // client side rendering
      fetch(data.url, getOptions(data))
        .then(async (response) => {

          // if(data.method == "PUT") {
          //   console.log(response);
          //   return
          // }

          //refresh token
          if (response.status === 401) {
            let refreshTokenResult = await refreshAccessToken();

            if (refreshTokenResult.status === 200) {
              let _data = await refreshTokenResult.json();
              common.localSet("authData", common.crypt(_data, true));
              const newAccessToken = _data.accessToken;
              const options = getOptions(data);
              options.headers.Authorization = `Bearer ${newAccessToken}`;
              return fetch(data.url, options).then(async (resp) => {
                return resp.text().then((data) => ({
                  status: resp.status,
                  data: data,
                }));
              });
            } else {
              localStorage.clear();
              common.redirect();
              return {
                status: 401,
              };
            }
          } else {
            return response.text().then((data) => ({
              status: response.status,
              data: data,
            }));
          }
        })
        .then((result) => {
          if (validateData(result)) {
            try {
              if(result.status == 204){
                result.data = {};
              } else {
                result.data = JSON.parse(result.data);
              }
            } catch (e) {
              result.data = {
                error: e.toString(),
              };
            }
            notify(data, result);
            response(result);
          }
        })
        .catch((err) => {
          let result = {
            status: 404,
            data: {
              error: err.toString(),
            },
          };
          notify(data, result);
          response(result);
        })
        .finally(() => {
          if (typeof final !== "undefined") {
            final();
          }
        });
    }
  },
};

// support
const getData = (data) => {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    data.notify = data.notify ?? true;
    // set url
    if (data.type == "third") {
      data.url = data.url + data.query;
    } else {
      data.url = services[data.url] + data.query;
      if (data.keyQuery != "") {
        for (var key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType == 2) {
        let bodyParam = [];
        for (var property in data.body) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
};

function getOptions(data) {
  let reqOptions = {
    method: data.method,
    headers: getHeaders(data),
  };

  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data) {
  // default
  let headers = {};

  // content types
  let contentTypes = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data",
  };
  if (data.cType !== 6) {
    headers["Content-Type"] = contentTypes[data.cType];
  }

  // extra content types
  let moreHeaderList = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (var item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }

  // authentication
  if (data.auth == "token") {
    let authData = common.getAuth();
    headers.token = authData;
    headers.type = "user";
  } else if (data.auth == "temp") {
    let tempData = common.tempDataGet();
    headers.Authorization = "Bearer " + tempData?.accessToken;
  } else if (data.auth == "basic") {
    headers.Authorization =
      "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  }

  return headers;
}

const validateData = (result) => {
  if (config.api.isExpired != "" && result.status == config.api.isExpired) {
    localStorage.clear();
    common.redirect();
    return false;
  } else {
    return true;
  }
};

const notify = (data, result) => {
  if (data.notify) {
    if (![200, 500, 400, 401].includes(result.status)) {
      common.notify("E", result.data.error);
    }
  }
};

// rToken
const refreshAccessToken = async () => {
  try {
    let authData = common.getAuth();
    const response = await fetch(services["ids_refresh_token"], {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: authData?.refreshToken,
      }),
    });

    return response;
  } catch (error) {
  }
};
