import { toast } from "react-toastify";
import { config } from "../helpers/config";
import { AES, enc } from "crypto-js";
import moment from 'moment';
import CryptoJS from "crypto-js";

export const common = {
  loadImg(img) {
    let result = require("../assets/img/" + img);
    return result;
  },
  notify(type, msg) {
    toast.dismiss();
    switch (type) {
      case "S":
        toast.success(msg, config.elements.toast);
        break;
      case "W":
        toast.warning(msg, config.elements.toast);
        break;
      case "E":
        toast.error(msg, config.elements.toast);
        break;
      case "I":
        toast.info(msg, config.elements.toast);
        break;
      case "M":
        toast(msg, config.elements.toast);
        break;
        default:
          toast.success(msg, config.elements.toast);
    }
  },
  getAuth() {
    let data = this.localGet("token", true);
    return data;
  },

  // encode decode
  crypt(str, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, config.cryptKey).toString();
  },

  deCrypt(str, json = false) {
    try {
      let bytes = AES.decrypt(str, config.cryptKey);
      let data = bytes.toString(enc.Utf8);
      if (data == "") {
        localStorage.clear();
        // this.redirect("register");
      } else {
        if (json) {
          return JSON.parse(data);
        } else {
          return data;
        }
      }
    } catch (err) {
      localStorage.clear();
      // this.redirect("register");
    }
  },
  // local storage
  localSet1(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  localGet1(key, json = false) {
    let value = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },
  localRemove(key) {
    localStorage.removeItem(key);
  },

  formatText (item) {
    const regex = /(<([^>]+)>)/gi;
    let text = item.question.content;
    // text = text.replace(regex, '');
    // text = common.replaceHTMLentities(text);
    const regex1 = /\[\[\\u2603(.*?)\]\]/gim;
    let content = text.replace('☃', '\\u2603');
    let question = {};
    let m = regex1.exec(content);
    let answers = {};
    if (m !== null) {
      m.forEach((match, groupIndex) => {
        if (groupIndex == 1 && match != '') {
          question.content = content.replace(`\[\[\\u2603${match}\]\]`, '');
          question.answers = item.question.widgets[match];
          question._id = item._id;
          question.answer = item.answer;
        }
      });
    }
    return question;
    // return text;
  },

  // local data
  localDataSet(value) {
    this.localSet("localData", value);
  },

  localDataGet() {
    let res = this.localGet("localData", true);
    this.localDataRemove();
    return res;
  },

  localDataRemove() {
    return this.localRemove("localData");
  },
  redirect(url = "") {
    window.location.href = config.public_url + url;
  },
  formatDate(date, format) {
    if (!format) format = 'D MMM - h:mm a';
    let formatedDate = moment
      .utc(date)
      .local()
      .format(format);
    return formatedDate;
  },
  remainingSecondsfromDate(date) {
    try {
      let endDate = moment(date);
      let startDate = moment(new Date());
      let ms = endDate.diff(startDate);
      var date = moment.duration(ms);
      let seconds = date.asSeconds();
      return seconds;
    } catch (e) {
      this.showError(e);
    }
  },
  remainingSecondstoDate(date) {
    try {
      let startDate = moment(date);
      let endDate = moment(new Date());
      let ms = endDate.diff(startDate);
      var date = moment.duration(ms);
      let seconds = date.asSeconds();
      return seconds;
    } catch (e) {
      this.showError(e);
    }
  },
  localSet(key, value) {
    // Your data to be encrypted
    let dataToEncrypt = value;

    if (typeof value === "object") {
      dataToEncrypt = JSON.stringify(value);
    }

    // Secret key for encryption (keep this secret)
    const secretKey = config.data_secret_key;

    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();

    // Store the encrypted data in localStorage
    localStorage.setItem(key, encryptedData);
  },
  localGet(key, json = false) {
    // Secret key (must match the one used for encryption)
    const secretKey = config.data_secret_key;

    // Retrieve the encrypted data from localStorage
    const encryptedData = localStorage.getItem(key);

    if (encryptedData) {
      // Decrypt the data
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);

      // If it's JSON data, parse it
      if (json) {
        try {
          return JSON.parse(decryptedData);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return null;
        }
      } else {
        return decryptedData;
      }
    }
  }
};
