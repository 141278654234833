import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "../assets/css/authlayout.css";
import "../assets/imgs/logo.svg";
function AuthLayout(props) {
  const imagePath = require("../assets/imgs/logo.svg").default;
  return (
    <div className="auth-bg">
      <div className="bg-img">
        <div className="container-fluid">
          <div className="logo-wrap">
            <img src={imagePath} alt="logo" height={108} width={331} />
          </div>
        </div>
        <Outlet {...props} />
      </div>
    </div>
  );
}

export default AuthLayout;
