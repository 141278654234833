import React from "react";
import AmlHashForm from "aml-hash-form";
import { useState, useEffect } from "react";

import { common } from "../helpers/common";
import { api } from "../helpers/api";
import { useNavigate } from "react-router-dom";

function Step2() {
  const formFields = {
    mobile: {
      validate: ["req", "min:10", "max:12", "type:+-number"],
    },
    email: { validate: ["req", "email"] },
  };
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);

  const { fields, errors, setValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

    // handler
  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  const Login = () => {
    try {
      let payload = {
        phone_number: fields?.mobile,
        email: fields?.email
      };
      // let payload = {
      //   phone_number: fields?.mobile
      // };
      // let data = {
      //   url: "auth_login",
      //   method: "PUT",
      //   auth: "token",
      //   body: payload,
      // };
      let data = {
        url: "auth_login_new",
        method: "POST",
        auth: "none",
        body: payload,
      };
  
      api.call(data, (response) => {
        if (response.data.http_code !== 200) {
          common.notify("E", response.data.message);
        } else {
          common.notify("S", "Login successfully");
          common.localSet('token', JSON.stringify(response.data.data.token));
          common.localSet(
            'userDetails',
            JSON.stringify(response.data.data),
          );
          common.localSet('userPhone', fields?.mobile);
          // navigate("/verification");
          navigate("/downloadquestionpaper");
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="row vertical-align bg ">
      <div className="col-md-4">
        <div className="auth-wrap">
          <div className="inner-wrap">
            <div className="step">
              {/* <span className="step-content">STEP 2</span> */}
              <span className="step-content">STEP 1</span>
            </div>
            <div className="title-wrap">
              <h1 className="auth-title">Enter your mobile </h1>{" "}
              {/* <h1 className="auth-title">number to create </h1> */}
              <h1 className="auth-title">number and Email to </h1>
              <h1 className="auth-title">login account </h1>
              {/* <p className="auth-desc">We will send you one time</p> */}
              {/* <p className="auth-desc"> password (OTP)</p> */}
            </div>

            <div className="phone-wrap">
              <label className="form-label" for="custom-select">
                Phone Number
              </label>
              <br></br>
              <div class="phone-input">
                <input type="text" class="country-code" value="+91" />
                <input
                  type="text"
                  class="phone-number"
                  placeholder="Mobile Number"
                  name="mobile"
                  value={fields.mobile}
                  onChange={handleChange}
                />
              </div>
              <p className="error-text">{errors.mobile}</p>
              <div className="mt-2">
                <label className="form-label" for="custom-select">
                  Email*
                </label>
                <br></br>
                <input
                  type="text"
                  class="login-email"
                  placeholder="Email"
                  name="email"
                  value={fields.email}
                  onChange={handleChange}
                />
                <p className="error-text">{errors.email}</p>
              </div>
              
              <div className="btn-wrap">
                <button
                  className="btn btn-primary save-btn"
                  onClick={onSubmit}
                >
                  Save and Continue
                </button>
              </div>
            </div>
            <div className="terms-wrap">
              <p className="terms">
                By Continuing. I confirm that I have read and agree to{" "}
              </p>
              <p className="terms">
                the<span className="terms-link"> Terms</span> & Condition and{" "}
                <span className="terms-link"> Privacy Policy</span>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step2;
