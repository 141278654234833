export const config = {
  public_url: process.env.REACT_APP_PUBLIC_URL,
  data_secret_key: process.env.REACT_APP_SECRET_KEY || "SECRET_KEY",
  // api
  api: {
    isExpired: 401,
    url: process.env.REACT_APP_API_URL
  },
  elements: {
    dp: {
      A: "MMMM DD, YYYY",
      B: "MMM DD, YYYY | hh:mm A",
      C: "MMM DD, YYYY hh:mm A",
      D: "MMM DD, YYYY | hh:mm:ss A",
    },
    singleDp: {
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MMMM DD, YYYY",
      },
      autoApply: true,
      autoUpdateInput: false,
      isClearable: true,
    },
    toast: {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
  },
};
