import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { common } from "../helpers/common";
import {useLocation} from 'react-router-dom';
import Countdown from 'react-countdown';
import { api } from "../helpers/api";

const answerLegends = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

const Review = () => {

  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [setting, setSetting] = useState();
  const [answered, setAnswered] = useState({
    selectedAnswers: [],
    totScore: 0,
    wrong: 0,
    correct: 0,
  });

  const getEndDate = (setting) => {
    if(setting && setting.startDate) {
      const startDate = new Date(setting.startDate);
      const testDurationInSeconds = Number(setting.testDuration);
      const endDate = new Date(startDate.getTime() + testDurationInSeconds * 60 * 1000);
      return endDate;
    }
    return null
  }

  useEffect(() => {

    let newSetting = common.localGet('setting', true);
    if(newSetting == null || new Date() > getEndDate(newSetting)) {
      navigate("/register");
    }

    const quesList = JSON.parse(common.localGet("question_paper"));
    if (quesList && quesList.length > 0) {
      quesList.forEach((q, i) => {
        q = common.formatText(q);
        quesList[i] = q;
      })
    }
    setSetting(newSetting);

    let answeredLocal = common.localGet("answered", true);
    setAnswered((prevState) => ({
      ...prevState,
      selectedAnswers: answeredLocal.selectedAnswers || [],
    }));
    setQuestions(quesList);

  }, []);

  const answerLayout = (key, i, item, ansI) => {
    if(answered && answered.selectedAnswers[i] && answered.selectedAnswers[i].key == key) {
      return (
        <div className="selected-answer">
          <span className="option-letter">{answerLegends[ansI]}</span>
          <span>
              {Object.keys(item)?.map((o, idx) => (
                // <span key={idx}>
                //   { item[o].content }
                // </span>
                <span key={idx} dangerouslySetInnerHTML={{ __html: item[o].content }}>
                </span>
              ))}
          </span>
        </div>
      )
    }
    return null
  }

  const goToQuestions = index => {
    navigate("/review");
    navigate("/questions", { state: { selIndex: index } });
  };

  const timeRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      if(!common.localGet("reviewsAutoSubmit")) {
        common.localSet("reviewsAutoSubmit", true);
        onSubmit();
      }
    }
    return (
      <p>
        <span>{hours}</span>
        <span>:</span>
        <span>{minutes}</span>
        <span>:</span>
        <span>{seconds}</span>
      </p>
    );
  };

  const onSubmit = () => {
    try {
      let answeredLocal = common.localGet("answered", true);
      answeredLocal.totScore = 0;
      answeredLocal.correct = 0;
      answeredLocal.wrong = 0;
      answeredLocal.selectedAnswers.map(i => {
        if (i && i.answer && i.answer[i.key] && i.answer[i.key].value) {
          answeredLocal.totScore = answeredLocal.totScore + 1;
          answeredLocal.correct = answeredLocal.correct + 1;
        } else {
          answeredLocal.wrong = answeredLocal.wrong + 1;
        }
      });
      let data = {
        url: "post_answers",
        auth: "token",
        method: "POST",
        body: { ...answeredLocal }
      };
      api.call(data, (response) => {
        if (response.data.http_code !== 200) {
          common.notify("E", response.data.message);
        } else {
          logout();
          common.notify("E", "Test has been submitted successfully");
        }
      }); 
    } catch (error) {
      console.log(error);
    }
  }

  const logout = _ => {
    common.localRemove('token');
    common.localRemove('userPhone');
    common.localRemove('question_paper');
    common.localRemove('setting');
    common.localRemove('answered');
    common.localRemove('userDetails');
    common.localRemove('questionsAutoSubmit');
    navigate("/testcompleted");
  };

  const getCountDown = () => {
    if(setting && setting.startDate) {
      const startDate = new Date(setting.startDate);
      const testDurationInSeconds = Number(setting.testDuration);
      const endDate = new Date(startDate.getTime() + testDurationInSeconds * 60 * 1000);
      return (
        <div>
          <Countdown date={endDate} renderer={timeRenderer} />
        </div>
      )
    }
    return null
  }

  return (
    <div className="page-wrap">
      <div className="container-fluid questions-header-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto question-header pb-3">
              <p className="qheader-txt1">
                8th Chapter Convention on Quality Concepts 3CQC - 2023
              </p>
              <p className="qheader-txt2">
                Convention theme: “Nurture Quality Concepts for a Better Future”
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid questions-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto question-list">
              <p>
                <span>Review</span>
              </p>
            </div>
            <div className="col-md-1 mx-auto question-list">
              <div className="timer-wrap">
                  { getCountDown() }
                  {/* {
                    setting && setting.startDate && (
                      <div>
                        <Countdown date={new Date(setting.startDate) + (setting.testDuration * 1000)} renderer={timeRenderer} />
                      </div>
                    )
                  } */}
                  {/* <p>
                    <span>02</span>
                    <span>:</span>
                    <span>59</span>
                    <span>:</span>
                    <span>55</span>
                  </p> */}
                </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container answer-wrap">
        <div className="row">
          <div className="col-md-10 mx-auto">
            {questions?.map((q, i) => (
            <div className="review-quest-wrap" key={i} onClick={() => goToQuestions(i)}>
              <p className="q-num">Q.{i + 1}</p>
              <p className="q-txt" dangerouslySetInnerHTML={{ __html: questions[i]?.content }}>
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since */}
              </p>
              {questions[i]?.answers?.content && 
                  Object.keys(questions[i].answers.content)?.map((key, ansI) => {
                    const item = questions[i].answers.content[key].content;
                    return answerLayout(key, i, item, ansI)
                })}
            </div>
            ))}

            {/* <div className="options-wrap" key={key + i}>
              <label htmlFor="q1-option1" className="options w-100">
                <div className="option-inner">
                  <span className="option-letter">{answerLegends[i]}.</span>
                  {Object.keys(item)?.map((o, idx) => (
                    <span key={idx}>
                      { item[o].content }
                    </span>
                  ))}
                </div>
              </label>
            </div> */}

            {/* <div className="review-quest-wrap">
              <p className="q-num">Q.2</p>
              <p className="q-txt">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since
              </p>
              <div className="selected-answer">
                <span className="option-letter">C.</span>
                <span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </span>
              </div>
            </div>
            <div className="review-quest-wrap">
              <p className="q-num">Q.3</p>
              <p className="q-txt">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since
              </p>
              <div className="selected-answer">
                <span className="option-letter">D.</span>
                <span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry
                </span>
              </div>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-10 mx-auto review-foot">
            <button className="btn btn-answer" onClick={() => onSubmit()}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
