import { useNavigate } from "react-router-dom";

function TestComplete() {
  const navigate = useNavigate();

  // const imageDoc = require("../assets/imgs/document-icn.svg").default;
  const tick = require("../assets/imgs/tick.svg").default;
  const rightArrow = require("../assets/imgs/right-arrow.svg").default;

  const logout = _ => {
    navigate("/");
  };

  return (
    <div className="row vertical-align bg ">
      <div className="col-md-4">
        <div className="auth-wrap">
          <div className="inner-wrap">
            <div className="step">
              <span className="step-content">Test Completed</span>
            </div>
              <div>
                <div className="img-error-wrap mt-5">
                  <img src={tick} alt="doc" width={125} height={125} />
                </div>
                <div className="progress-wrap">
                  {/* <img src={imageDoc} alt="doc" width={74} height={86} /> */}
                  <h1 className="auth-title mt-3">The test answers has been successfully submitted.</h1>
                  {/* <h2 className="auth-title">Download.</h2> */}
                </div>
                
                <div className=" get-started mt-5">
                  <button className="btn btn-primary save-btn" onClick={logout}>
                    Home
                  </button>
                  <img
                    src={rightArrow}
                    alt="doc"
                    width={11}
                    height={19}
                    className="arrow-right"
                  />
                </div>
              </div>
          </div>
        </div>

        {/* <div className="auth-wrap mt-5">
          <div className="inner-wrap">
            <div className="step">
              <span className="step-content">STEP 4</span>
            </div>
            <div className="progress-wrap">
              <img src={imageDoc} alt="doc" width={74} height={86} />
              <h1 className="auth-title mt-3">Question Paper</h1>
              <h2 className="auth-title">Download Error</h2>
            </div>
            <div className="img-error-wrap mt-5">
              <img src={cloudImg} alt="doc" width={131} height={117} />
            </div>
            <div className="try-wrap ">
              <img
                src={refresh}
                alt="doc"
                width={22}
                height={26}
                className="me-3"
              />
              <p className="try-again">Try Again</p>
            </div>
          </div>
        </div> */}

        {/* <div className="auth-wrap mt-5">
          <div className="inner-wrap">
            <div className="step">
              <span className="step-content">STEP 4</span>
            </div>
            <div className="progress-wrap">
              <img src={imageDoc} alt="doc" width={74} height={86} />
              <h1 className="auth-title mt-3">Question Paper</h1>
              <h2 className="auth-title">Download.</h2>
            </div>
            <div className="img-error-wrap mt-5">
              <img src={tick} alt="doc" width={125} height={125} />
            </div>
            <div className=" get-started mt-5">
              <button className="btn btn-primary save-btn">
                Get Started Now!
              </button>
              <img
                src={rightArrow}
                alt="doc"
                width={11}
                height={19}
                className="arrow-right"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default TestComplete;
