import React, { useState, useEffect } from "react";

const countDownTime = new Date("2023-09-20T23:59:59");
const getTimeLeft = () => {
  const totalTimeLeft = countDownTime - new Date();
  const Days = Math.floor(totalTimeLeft / (1000 * 60 * 60 * 24));
  const Hrs = Math.floor((totalTimeLeft / (1000 * 60 * 60)) % 24);
  const Min = Math.floor((totalTimeLeft / (1000 * 60)) % 60);
  const Sec = Math.floor((totalTimeLeft / 1000) % 60);
  return { Days, Hrs, Min, Sec };
};

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState(() => getTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeLeft());
    }, 1000);
    return () => {
      console.log("Completed Timer");
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="countdown-wrap pb-4">
      {Object.entries(timeLeft).map((el) => {
        const label = el[0];
        const value = el[1];
        return (
          <div className="box" key={label}>
            <div className="value">
              <span>{value}</span>
            </div>
            <span className="label">{label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Countdown;
